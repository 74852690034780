<template>
  <div id="AccManagementInspectPoint">
    <CSBreadcrumb/>
    <div class="page-container">
      <table
        class="footable table table-stripped toggle-arrow-tiny"
        data-page-size="15"
      >
        <thead>
          <tr
            class="head_row"
          >
            <th>巡检点</th>
            <th>位置</th>
            <th>签到</th>
            <th>签到方式</th>
            <th>访问记录</th>
<!--            <th>登记记录</th>-->
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="inspectPoint in inspectPointList"
            :key="inspectPoint.id"
            style="height: 50px;"
          >
            <td class="center">
              {{ inspectPoint.name }}
            </td>
            <td class="center">
              {{ inspectPoint.addr || "没有位置" }}
            </td>
            <td class="center">
              {{ inspectPoint.checkinMethod === 0 ? "不需要" : "需要" }}
            </td>
            <td class="center">
              {{ inspectPoint.checkinMethod === 0 ? '-' : inspectPoint.checkinMethod === 1 ? "定位签到" : "拍照签到" }}
            </td>
            <td class="center">
              <span
                  class="allow-click"
                  @click="lookAccessRecords(inspectPoint.locationId)"
                >
                  {{ inspectPoint.inspectionLogCount }}
              </span>
            </td>
<!--            <td class="center">
              <span
                  class="allow-click"
                  @click="lookRegisterInfo(inspectPoint.id)"
                >
                  {{ inspectPoint.registerCount }}
              </span>
            </td>-->
          </tr>
        </tbody>
      </table>
    </div>
    <!-- 查看登记记录对话框 -->
    <CSDialog
      :dialogVisible="lookRegisterVisibel"
      dialogWidth="1080px"
      dialogCancelBtnText="关闭"
      dialogTitle="登记记录"
      @onClose="lookRegisterVisibel = false"
      :dialogShowConfirmBtn="false"
    >
      <div
        slot="dialog-content"
        style="font-size: 15px; padding: 30px"
      >
        <div
          class="cs-select"
          style="
            margin-right: 10px;
            vertical-align: middle;
            width: 240px;
          "
        >
          <el-date-picker
            type="datetime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="请选择开始时间"
            v-model="queryRegisterInfo.startDate"
            :editable="false"
          >
          </el-date-picker>
          <i class="icon el-icon-caret-bottom" style="width: 26px"></i>
        </div>
        <span
          style="
            display: inline-block;
            vertical-align: middle;
            height: 2px;
            width: 20px;
            margin-right: 0;
            background-color: #999;
          "
        >
        </span>
        <div
          class="cs-select"
          style="
            margin-left: 10px;
            vertical-align: middle;
            margin-right: 20px;
            width: 240px;
          "
        >
          <el-date-picker
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="请选择结束时间"
            v-model="queryRegisterInfo.endDate"
            :editable="false"
          >
          </el-date-picker>
          <i class="icon el-icon-caret-bottom" style="width: 26px"></i>
        </div>
        <CSSelect
          height="30px"
        >
          <select
            style="width: 140px"
            v-model="queryRegisterInfo.registerId"
          >
            <option value="">登记人不限</option>
            <option
              v-for="staff in staffList"
              :key="staff.id"
              :value="staff.id"
            >{{ staff.name }}</option>
          </select>
        </CSSelect>
        <input
          type="text"
          class="notice_name"
          placeholder="搜索登记说明"
          style="height: 30px; width: 240px; padding-left: 10px;"
          v-model="queryRegisterInfo.description"
        />
        <button
          type="button"
          class="btn btn-primary"
          style="margin-left: 20px; font-size: 14px; height: 30px; padding: 0; width: 50px"
          @click="getRegister()"
        >
          查询
        </button>
        <div
          style="height: 340px;width: 1035px; overflow-y: auto; padding-bottom: 10px;"
        >
          <table
            border="1"
            class="footable table table-stripped toggle-arrow-tiny modal-inner-table"
            style="width: 1020px; margin: 20px 0 0 1px;"
          >
            <thead>
              <tr class="head_row">
                <th style="width: 225px">登记时间</th>
                <th style="width: 246px">登记人</th>
                <th>登记说明</th>
                <th style="width: 88px">图片</th>
              </tr>
            </thead>
            <tbody
              v-if="registerInfo && registerInfo.length > 0"
            >
              <tr
                class="head_row"
                v-for="register in registerInfo"
                :key="register.id"
              >
                <td class="center">
                  {{ register.createTime }}
                </td>
                <td class="center">
                  {{ register.userName }}
                </td>
                <td class="center"  style="line-height: 35px">
                  {{ register.description }}
                </td>
                <td class="center">
                  <span
                    class="allow-click"
                    @click="lookImages(register.picture)"
                    v-if="register.picture != undefined"
                  >
                    查看
                  </span>
                  <span
                    v-else
                  >
                  -
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </CSDialog>
    <ViewImageModal name="viewImageModal" componentName="ViewImageModal">
    </ViewImageModal>
    <Pagination
      name="Pagination"
      component="Pagination"
    ></Pagination>
  </div>
</template>

<script>
import CSDialog from "@/components/common/CSDialog";
import CSSelect from "@/components/common/CSSelect";
import CSBreadcrumb from "@/components/common/CSBreadcrumb";
import Pagination from "@/components/Pagination";
import ViewImageModal from "@/components/ViewImageModal";
import dayjs from "dayjs";

import {
  queryLedgerInspectPoint,
  queryRegister,
  queryInspectLog,
  } from "@/requestUrl"

  export default {
    name: "AccManagementInspectPoint",
    props: {
      pointIds: String
    },
    data() {
      return {
        inspectPointList: [],
        accessRecordsInfo: [],
        checkItemsInfo: [],
        registerInfo: [],
        staffList: [],
        lookRegisterVisibel: false,
        queryRegisterInfo: {
          regionCode: "",                  //区域code  必填
          inspectPointId: "",              //巡检点id  必填
          pageSize: 10,                    //默认10
          startDate: "",                   //查询开始时间
          endDate: "",                     //结束时间
          registerId: "",                  //登记人id
          description: "",                 //搜索登记说名
        },
      }
    },
    components: {
      CSDialog,
      CSSelect,
      ViewImageModal,
      Pagination,
      CSBreadcrumb,
    },
    created() {
      this.getInspectPoint();
      this.$vc.on(this.$route.path, "pagination","page_event",(pageNo)=>{
        this.getInspectPoint(pageNo);
      })
    },
    methods: {
      // 获取相应的巡检点
      getInspectPoint(pageNo = 1,pageSize = 10) {
        let params = {
            regionId: this.$vc.getCurrentRegion().code,
            pointIds: this.pointIds,
            pageNo,
            pageSize,
        };
        this.$fly
            .post(queryLedgerInspectPoint, params)
            .then(res => {
              if (res.code !== 0) {
                return;
              }
              if(pageNo === 1){
                this.$vc.emit(this.$route.path, "pagination","init",{
                  total: res.data.total || res.data.datas.length,
                  currentPage: pageNo,
                  pageSize,
                })
              }
              this.inspectPointList = res.data.datas;
            })
      },


      // 获取登记记录
      getRegister() {
        let params = {
          ...this.queryRegisterInfo,
          regionCode: this.$vc.getCurrentRegion().code,
          inspectPointId: this.inspectPointId,
        };
        this.$fly
            .post(queryRegister, params)
            .then(res => {
              if (res.code !== 0) {
                return;
              }
              this.registerInfo = res.data || [];
            })
      },
      lookAccessRecords(locationId) {
        this.$router.push({name: 'AccessRecords', params: {locationId}})
      },
      // 查看登记记录
      lookRegisterInfo(inspectPointId) {
        this.queryRegisterInfo = {
          regionCode:"",
          inspectPointId:"",
          pageSize:10,
          startDate: dayjs().subtract(7, "day").format("YYYY-MM-DD") + " 00:00",
          endDate: dayjs().format("YYYY-MM-DD") + " 23:59",
          registerId:"",
          description: "",
        }
        this.inspectPointId = inspectPointId;
        this.lookRegisterVisibel = true;
        this.getRegister();
      },
      // 查看图片
      lookImages(photos) {
        this.$vc.emit(this.$route.path, "viewImageModal", "view", JSON.parse(photos));
      },
    },
  }
</script>

<style lang="stylus" scoped>
.nav-bar
  width 100%
  background #FFFFFF
  box-shadow 0px 5px 10px 0px rgba(0, 0, 0, 0.1)
  font-size 24px
  color var(--pc-theme-color)
  padding 18px 30px
  box-sizing border-box
  div
    line-height 24px
    display inline-block
.page-container
  background #fff
  margin 20px 0
  box-shadow -10px 0 20px 0 rgba(0, 0, 0, 0.1)
.head_row
  height 50px
  text-align center
  th
    padding 0
    line-height 50px
.center
  text-align center
  padding: 0
  line-height: 50px
.notice_name
    width 400px
    height 38px
    border 1px solid #999
    border-radius 4px
    vertical-align middle
    margin-left 20px
input::-webkit-input-placeholder
  color: #999;
input::-moz-placeholder
  color: #999;
input::-moz-placeholder
  color: #999;
input::-ms-input-placeholder
  color: #999;
</style>
